import React, { CSSProperties } from "react";

import { styled, Theme, theme } from "@styles/theme";
import { dynamicColor, ColorProps } from "@styles/helpers";
import {
  BreakPoints,
  formatProp,
  Responsive,
  ResponsiveProps,
  responsiveStyling,
} from "@styles/responsive";

type AditionalStyles = {
  responsiveStyles?: {
    [k in keyof CSSProperties]: CSSProperties[k] | Array<CSSProperties[k]>;
  };
};

export type Props = ResponsiveProps &
  AditionalStyles &
  ColorProps & {
    width?: CSSProperties["width"];
    height?: CSSProperties["height"];
    paragraph?: boolean;
    cursor?: React.CSSProperties["cursor"];
    display?:
      | CSSProperties["display"]
      | { [key in BreakPoints]?: string | number };
    size?: Responsive<number>;
    align?: CSSProperties["textAlign"];
    weight?: Responsive<CSSProperties["fontWeight"]>;
    transform?: CSSProperties["textTransform"];
    theme?: Theme;
    noWrap?: boolean;
    ellipsis?: boolean;
  };

export const Text: React.FC<
  Omit<React.HTMLProps<HTMLElement>, "ref" | "display" | "size"> & Props
> = ({ paragraph, ellipsis, children, ...rest }) => {
  const Container = (paragraph ? styled.p : styled.span)<Props>`
    ${dynamicColor};

    font-family: ${theme.fonts.main};
    text-transform: ${(p) => p?.transform};
    text-align: ${(p) => p?.align};
    cursor: ${(p) => p?.cursor};
    white-space: ${(p) => p?.noWrap && "nowrap"};

    ${(p) =>
      responsiveStyling({
        fontSize: formatProp<CSSProperties["fontSize"]>(p.size, "14px"),
        fontWeight: formatProp<CSSProperties["fontWeight"]>(p.weight, "400"),
        display: formatProp<CSSProperties["display"]>(p.display),
        ...p.responsiveStyles,
      })};
                      
    ${
      ellipsis &&
      `
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `
    }
`;

  return <Container {...rest}>{children}</Container>;
};
