import baseStyled, { CreateStyled } from "@emotion/styled";
import { css } from "@emotion/core";

const fonts = {
  main: `"Nunito Sans", sans-serif`,
  code: `"Fira Code", monospace;`,
};

const colors = {
  transparent: {
    "0": "transparent",
    "100": "transparent",
    "200": "transparent",
    "300": "transparent",
    "400": "transparent",
    "500": "transparent",
    "600": "transparent",
    "700": "transparent",
    "800": "transparent",
    "900": "transparent",
  },

  colorBackground: {
    "0": "#F0F4F5",
    "100": "#F0F4F5",
    "200": "#F0F4F5",
    "300": "#F0F4F5",
    "400": "#F0F4F5",
    "500": "#F0F4F5",
    "600": "#F0F4F5",
    "700": "#F0F4F5",
    "800": "#F0F4F5",
    "900": "#F0F4F5",
  },

  primary: {
    "0": "#7693AB",
    "100": "#7693AB",
    "200": "#647D91",
    "300": "#536778",
    "400": "#41515E",
    "500": "#303C46",
    "600": "#303C46",
    "700": "#303C46",
    "800": "#303C46",
    "900": "#303C46",
  },

  neutral: {
    "0": "#FFFFFF",
    "100": "#F7FAFC",
    "200": "#EDF2F7",
    "300": "#E2E8F0",
    "400": "#CBD5E0",
    "500": "#A0AEC0",
    "600": "#718096",
    "700": "#4A5568",
    "800": "#2D3748",
    "900": "#1A202C",
  },

  blue: {
    "0": "#EBF8FF",
    "100": "#EBF8FF",
    "200": "#BEE3F8",
    "300": "#90CDF4",
    "400": "#63B3ED",
    "500": "#4299E1",
    "600": "#3182CE",
    "700": "#2B6CB0",
    "800": "#2C5282",
    "900": "#2A4365",
  },

  red: {
    "0": "#FFF5F5",
    "100": "#FFF5F5",
    "200": "#FED7D7",
    "300": "#FEB2B2",
    "400": "#FC8181",
    "500": "#F56565",
    "600": "#E53E3E",
    "700": "#C53030",
    "800": "#9B2C2C",
    "900": "#742A2A",
  },

  yellow: {
    "0": "#FFFFF0",
    "100": "#FFFFF0",
    "200": "#FEFCBF",
    "300": "#FAF089",
    "400": "#F6E05E",
    "500": "#ECC94B",
    "600": "#D69E2E",
    "700": "#B7791F",
    "800": "#975A16",
    "900": "#744210",
  },

  green: {
    "0": "#F0FFF4",
    "100": "#F0FFF4",
    "200": "#C6F6D5",
    "300": "#9AE6B4",
    "400": "#68D391",
    "500": "#48BB78",
    "600": "#38A169",
    "700": "#2F855A",
    "800": "#276749",
    "900": "#22543D",
  },

  orange: {
    "0": "#FFFAF0",
    "100": "#FFFAF0",
    "200": "#FEEBC8",
    "300": "#FBD38D",
    "400": "#F6AD55",
    "500": "#ED8936",
    "600": "#DD6B20",
    "700": "#C05621",
    "800": "#9C4221",
    "900": "#7B341E",
  },

  purple: {
    "0": "#FAF5FF",
    "100": "#FAF5FF",
    "200": "#E9D8FD",
    "300": "#D6BCFA",
    "400": "#B794F4",
    "500": "#9F7AEA",
    "600": "#805AD5",
    "700": "#6B46C1",
    "800": "#553C9A",
    "900": "#44337A",
  },

  white: {
    "0": "#FFFFFF",
    "100": "#FFFFFF",
    "200": "#FFFFFF",
    "300": "#FFFFFF",
    "400": "#FFFFFF",
    "500": "#FFFFFF",
    "600": "#FFFFFF",
    "700": "#FFFFFF",
    "800": "#FFFFFF",
    "900": "#FFFFFF",
  },

  black: {
    "0": "#000000",
    "100": "#000000",
    "200": "#000000",
    "300": "#000000",
    "400": "#000000",
    "500": "#000000",
    "600": "#000000",
    "700": "#000000",
    "800": "#000000",
    "900": "#000000",
  },
};

const shadow = {
  xs: `0px 0px 2px rgba(26, 32, 44, 0.12),
       0px 2px 2px rgba(26, 32, 44, 0.24)`,
  md: `0px 4px 4px rgba(26, 32, 44, 0.24),
       0px 0px 4px rgba(26, 32, 44, 0.12)`,
  hover: `0px 2px 8px rgba(26, 32, 44, 0.24),
          0px 0px 2px rgba(26, 32, 44, 0.12)`,
  focus: `0px 0px 8px ${colors.blue[500]}, inset 0px 0px 0px 2px ${colors.blue[200]}`,
};

const gradients = {
  lightRed: `linear-gradient(240.43deg, ${colors.red[100]} 0%, ${colors.red[200]} 100%)`,
  red: `linear-gradient(90.35deg, ${colors.red[600]} 0%, ${colors.red[400]} 100%)`,
  green: `linear-gradient(90.35deg, ${colors.green[600]} 0%, ${colors.green[400]} 100%)`,
  blue: `linear-gradient(145.31deg, ${colors.purple[600]} 0%, ${colors.blue[500]} 100%)`,
  orange: `linear-gradient(145.31deg, ${colors.orange[600]} 0%, ${colors.yellow[500]} 100%);`,
  purple: `linear-gradient(274.93deg, ${colors.purple[800]} -17.83%, ${colors.neutral[800]} 114.03%)`,
};

const spacer = 16;

const spacing = {
  none: 0,
  auto: "auto",
  /** 4px */
  xxs: `${spacer * 0.25}px`,
  /** 8px */
  xs: `${spacer * 0.5}px`,
  /** 12px */
  sm: `${spacer * 0.75}px`,
  /** 16px */
  md: `${spacer * 1}px`,
  /** 24px */
  lg: `${spacer * 1.5}px`,
  /** 32px */
  xg: `${spacer * 2}px`,
  /** 40px */
  xxg: `${spacer * 2.5}px`,
  /** 48px */
  xxxg: `${spacer * 3}px`,
  /** 64px */
  hg: `${spacer * 4}px`,
  /** 72px */
  xhg: `${spacer * 4.5}px`,
  /** 80px */
  xxhg: `${spacer * 5}px`,
  /** 96px */
  xxxhg: `${spacer * 6}px`,
};

const radius = "4px";

const breakpoints = {
  sm: 600,
  md: 1024,
  lg: 1440,
  xg: 1920,
};

export const theme = {
  fonts,
  colors,
  spacing,
  radius,
  shadow,
  breakpoints,
  gradients,
  layout: { container: "1440px" },
};

export const graphColors: ColorDotWeight[] = [
  "red.500",
  "purple.500",
  "orange.500",
  "yellow.500",
  "blue.500",
  "green.500",
  "red.400",
  "purple.400",
  "orange.400",
  "yellow.400",
  "blue.400",
  "red.300",
  "orange.300",
  "yellow.300",
  "purple.300",
];

export const severityColors: { [key: string]: ColorDotWeight } = {
  high: "red.500",
  medium: "yellow.400",
  low: "blue.400",
};

export const GlobalStyle = css`
  @import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap");
  @import url("https://fonts.googleapis.com/css?family=Fira+Code&display=swap");
  body {
    background-color: ${colors.colorBackground["100"]};
    min-height: 100vh;
    font-family: ${fonts.main};
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  #__next {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow-x: hidden;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  .ReactModal__Content {
    overflow: auto !important;
    scrollbar-width: thin;
    scrollbar-color: ${`${colors.neutral["200"]} ${colors.neutral["400"]}`};

    /* IE?? */
    scrollbar-face-color: ${colors.neutral["200"]};
    scrollbar-shadow-color: ${colors.neutral["400"]};
    scrollbar-highlight-color: ${colors.neutral["400"]};

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      width: 8px;
      height: 8px;
      border-radius: 100px;
    }
    &::-webkit-scrollbar {
      background: ${colors.neutral["200"]};
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.neutral["400"]};
    }
  }
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  ::placeholder {
    color: ${colors.primary["100"]};
    font-weight: 400;
    opacity: 1;
  }

  ::selection {
    background: ${colors.blue[200]};
  }

  ::moz-selection {
    background: ${colors.blue[200]};
  }

  /* Clear chrome's blue background on autofilled inputs */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: unset;
    -webkit-text-fill-color: unset;
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .tippy-popper {
    position: fixed;
  }
`;

export const styled = baseStyled as CreateStyled<typeof theme>;
export type Theme = typeof theme;
export type PropsWithTheme<P> = P & { theme: Theme };

export type ColorDotWeight =
  `${keyof Theme["colors"]}.${keyof Theme["colors"]["transparent"]}`;
export type ColorName = keyof Theme["colors"];
export type ColorWeight = keyof Theme["colors"]["transparent"];
